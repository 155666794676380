<template>
  <div>
    <div class="header">
      <div class="header_top">
        当前位置:
        <span>企业管理</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">{{title}}</span>
        <span class="clickColor" @click="goBack">返回</span>
      </div>
    </div>
    <el-card>
      <div class="text item">
        <el-form
          label-position="top"
          width="900px"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="ruleForm"
          label-width="80px"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="企业名称" prop="workEnterpriseCode">
                <el-select
                  v-model="ruleForm.workEnterpriseCode"
                  filterable
                  :placeholder="'请选择企业名称'"
                  style="width: 100%"
                  @change="getCity"
                >
                  <el-option
                    v-for="item in enterpriseSelectList"
                    :key="item.code"
                    :label="item.name "
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业区域" prop="provice">
                <el-select
                  v-model="ruleForm.workEnterpriseArea"
                  style="width:100%"
                  filterable
                  :placeholder="'请输入企业区域'"
                >
                  <el-option-group
                    v-for="group in areaSelectList"
                    :key="group.provinceName"
                    :label="group.provinceName"
                  >
                    <span style="color:red" slot="label">{{group.provinceName}}1</span>

                    <el-option
                      v-for="item in group.citys"
                      :key="item.code"
                      :label="item.cityName"
                      :value="item.code"
                    ></el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="合同名称" prop="name">
                <el-input v-model="ruleForm.name" :placeholder="'请输入合同名称'"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="合同模板" prop="templateCode">
                <el-select
                  v-model="ruleForm.templateCode"
                  filterable
                  :placeholder="'请选择合同模板'"
                  style="width: 100%"
                  @change="inputChange"
                >
                  <el-option
                    v-for="item in contractTemplateMenuList"
                    :key="item.code"
                    :label="item.name "
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="合同类型" prop="type">
                <el-radio-group v-model="ruleForm.type">
                  <el-radio label="1" :disabled="true">入职合同</el-radio>
                  <el-radio label="2" :disabled="true">离职合同</el-radio>
                  <el-radio label="3" :disabled="true">其 他</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="模板内容" prop="content">
            <TinyEditor v-model="ruleForm.content" :configStr="'defined_text'"></TinyEditor>
          </el-form-item>
          <div class="templateClass">
            <div class="templateClass_left">
              <span class="clickColor" @click="seeTemplate('ruleForm')">模板预览</span>
              <span>指定签署方标记后可查看盖章效果</span>
            </div>
            <div class="templateClass_center">
              <el-button type="primary" @click="submitForm('ruleForm')" style="margin-right:10px">提交</el-button>
              <el-popconfirm title="是否取消已经编辑合同吗？" @confirm="closeDialog()">
                <el-button slot="reference">取消</el-button>
              </el-popconfirm>
            </div>
          </div>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<script>

import { api } from '/src/api/base';
export const workEnterpriseList = api()('work_enterprise.list.json');
// 合同模板列表
export const contractTemplateList = api()('contractTemplate.list.json');
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json');
export const ContractTemplateEnterpriseAdd = api()('contractTemplate.enterprise.add.json');
export const ContractTemplateEnterpriseEdit = api()('contractTemplate.enterprise.edit.json');
export const contractTemplateDescribe = api()('contractTemplate.describe.json');
export const ContractTemplateEnterpriseInfo = api()('contractTemplate.enterprise.info.json');

export default {
  components: {
    TinyEditor: () => import('/src/components/tinyEditor')
  },
  data () {
    return {
      code: '',
      title: '',
      enterpriseSelectList: [],
      areaSelectList: [],
      contractTemplateMenuList: [],

      ruleForm: {
        workEnterpriseCode: '',
        name: '',
        workEnterpriseArea: '',
        templateCode: '',
        config: '',
        type: '',
        content: ''
      },
      totalPage: '',
      pageNumber: 1,
      pageSize: 10,
      rules: {
        workEnterpriseCode: [{ required: true, message: '请选择企业名称', trigger: 'blur' }],
        name: [{ required: true, message: '请输入合同名称', trigger: 'blur' }],
        workEnterpriseArea: [{ required: true, message: '请选择企业区域', trigger: 'blur' }],
        templateCode: [{ required: true, message: '请选择合同模板', trigger: 'blur' }],

        type: [{ required: true, message: '请选择合同类型', trigger: 'blur' }],
        content: [{ required: true, message: '请选择合同内容', trigger: 'blur' }]
      }
    };
  },
  created () {
    this.title = this.$route.query.title
    this.code = this.$route.query.code
    this.open()
  },

  methods: {
    open () {
      if (this.title === '编辑合同配置') {
        this.getInfo()
      }
      this.getWorkEnterpriseList()
      this.getContractTemplateList()
    },
    getWorkEnterpriseList () {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      workEnterpriseList(params).then((res) => {
        this.totalPage = Math.ceil(res.total / 10);
        this.enterpriseSelectList = this.enterpriseSelectList.concat(res.list)

      });
    },
    async selectLoadMore () {

      this.pageNumber = this.pageNumber + 1;
      if (this.pageNumber > this.totalPage) return;
      await this.getWorkEnterpriseList(); // 请求接口
    },
    getInfo () {
      const params = {
        code: this.$route.query.code
      };
      ContractTemplateEnterpriseInfo(params).then((res) => {

        this.ruleForm.workEnterpriseCode = res.workEnterpriseCode
        this.getCity(this.ruleForm.workEnterpriseCode)
        this.ruleForm.workEnterpriseArea = res.workEnterpriseArea
        this.ruleForm.name = res.name
        this.ruleForm.templateCode = res.templateCode
        this.ruleForm.type = res.type
        this.ruleForm.content = res.content
        this.ruleForm.config = res.config
        console.log(res, 2222)
      });
    },

    seeTemplate (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const params = {
            ...this.ruleForm,

          };
          if (this.code) {
            const res = await ContractTemplateEnterpriseEdit({
              ...params,
              code: this.code,

            });
            console.log(res);
            this.$router.push({
              path: '/system/contractTemplate/detail',
              query: { code: this.code, title: '合同配置' }
            });
          } else {
            console.log(111);
            const res = await ContractTemplateEnterpriseAdd({
              ...params,
            });
            this.code = res

            this.$router.push({
              path: '/system/contractTemplate/detail',
              query: { code: this.code, name: 'add', title: '合同配置' }
            });
          }
        }
      });

    },
    async getContractTemplateList () {
      const res = await contractTemplateList({
        pageNumber: 1,
        pageSize: 1000
      });
      // console.log(res.list);
      this.contractTemplateMenuList = res.list
      console.log(this.contractTemplateMenuList);
    },
    goBack () {
      this.$router.push({
        path: '/system/enterpriseContract/List'
      });

    },
    async inputChange (val) {
      this.ruleForm.type = this.contractTemplateMenuList.filter(it => it.code === val)[0].type
      const res = await contractTemplateDescribe({
        code: val
      });
      this.ruleForm.content = res.content
      this.ruleForm.config = res.config
      console.log(this.ruleForm.content);
    },
    submitForm (formName) {

      this.$refs[formName].validate(async valid => {

        if (valid) {
          const params = {
            ...this.ruleForm
          };
          if (this.code) {
            await ContractTemplateEnterpriseEdit({
              ...params,
              code: this.code,
            });
            window.history.go(-1);

          } else {
            console.log(333);
            const res = await ContractTemplateEnterpriseAdd({
              ...params,

            });

            this.code = res.code;
            this.$message.success('合同模板创建成功！');
            window.history.go(-1);
          }
        } else {
          return false;
        }
      });
    },
    getCity (val) {
      if (val) {
        const params = {
          workEnterpriseCode: val
        };
        workEnterpriseAreaContainArea(params).then(res => {
          this.areaSelectList = res

        });
      }
      else {
        this.areaSelectList = []
        this.ruleForm.workEnterpriseArea = ''
      }
    },
    closeDialog () {
      window.history.go(-1);
    }
  }
};
</script>

<style scoped>
.el-form-item {
  text-align: left;
}
.header_top {
  align-items: baseline;
}
.templateClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.templateClass_left {
  font-size: 14px;
}
.templateClass_left .clickColor {
  margin-right: 10px;
}
</style>
